<template>
  <div class="product-section mb_title-xl">
    <div class="product-section__title-wrapper mb_m">
      <div class="product-section__title font font_title-xl font_bold font_uppercase">
        {{ title }}
      </div>
    </div>

    <div class="product-section__grid">
      <ProductCard v-for="(item, index) in products" :key="index" :item="item" />
      <div class="product-section__controls">
        <Button v-if="btnLink" :to="localePath(btnLink)" class="product-section__btn" size="sm" theme="border">
          {{ $t('catalog.showAll') }}
        </Button>
      </div>

      <DynamicBanner
        v-if="banner"
        :class="{ 'product-section__banner_left': bannerLeft }"
        :info="banner"
        :ratio="ratio"
        :sizes="sizes"
        class="product-section__banner"
        loading="default"
      />
    </div>
  </div>
</template>

<script>
import ProductCard from '~/components/catalog/productCard/index.vue';
import Button from '~/components/elements/Button';
import DynamicBanner from '~/components/elements/DynamicBanner.vue';

export default {
  name: 'ProductSection',
  components: { DynamicBanner, ProductCard, Button },
  props: {
    title: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    btnLink: {
      type: String,
      default: null,
    },
    banner: {
      type: Object,
      default: null,
    },
    bannerLeft: {
      type: Boolean,
      default: false,
    },
    ratio: {
      type: Object,
      default() {
        return {};
      },
    },
    sizes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.product-section {
  position: relative;

  &__title {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__controls {
    @include bp('max', $md3) {
      width: 100%;
      padding-bottom: 16px;
      grid-column: 1/3;
      grid-row: 3;
    }

    @include bp('min', $md3) {
      min-width: 0;
      max-width: none;
      margin: 0;
      padding: 0;
    }
  }

  &__btn {
    position: absolute;

    top: 0;
    right: 0;
    min-width: max-content;

    @include bp('max', $md3) {
      position: static;
      width: 100%;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 24px;

    @include bp('max', 1024px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
    }

    @include bp('max', $md3) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-gap: 16px 8px;
    }
  }

  &__banner {
    margin-bottom: 0;
    grid-column: 3/5;
    grid-row: 1/3;

    &_left {
      grid-column: 1/3;
    }

    @include bp('max', 1024px) {
      grid-column: 2/4;
      grid-row: 1;

      &_left {
        grid-column: 1/3;
      }
    }

    @include bp('max', $md3) {
      grid-column: 1/3;
      grid-row: 4;
    }
  }
}
</style>
