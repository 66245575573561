<template>
  <div class="popular-brands mb_title-xl">
    <div class="popular-brands__title font font_title-xl font_uppercase font_bold">
      {{ $t('catalog.popularBrands') }}
    </div>
    <div class="popular-brands__wrapper">
      <ul v-if="preparedBrands.image.length > 1" class="popular-brands__list">
        <li v-for="(item, index) in preparedBrands.image" :key="index" class="popular-brands__item">
          <nuxt-link :to="localePath(item.to)" class="popular-brands__link">
            <CustomImage :alt="item.name" :image-id="item.image.id" aspect-ratio="39.85%" />
          </nuxt-link>
        </li>
      </ul>
      <ul v-if="preparedBrands.text.length > 1" class="popular-brands__list popular-brands__list_text">
        <li v-for="(item, index) in preparedBrands.text" :key="index" class="popular-brands__item">
          <nuxt-link :to="localePath(item.to)" class="popular-brands__link font font_grey">
            {{ item.name }}
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CustomImage from '~/components/elements/CustomImage';
import { prepareBrand } from '~/plugins/helpers/catalog';

export default {
  name: 'PopularBrands',
  components: { CustomImage },
  props: {
    brands: {
      type: Array,
      required: true,
    },
  },
  computed: {
    preparedBrands() {
      const items = {
        image: [],
        text: [],
      };

      this.brands.forEach((item) => {
        const brand = prepareBrand(item);
        item.image ? items.image.push(brand) : items.text.push(brand);
      });

      return items;
    },
  },
};
</script>

<style lang="scss">
.popular-brands {
  &__title {
    margin-bottom: 3.6rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    @include bp('max', 1023px) {
      justify-content: space-between;
    }

    &_text {
      justify-content: center;
      margin-top: 4.8rem;

      @include bp('max', 360px) {
        gap: 2.4rem;
      }

      .popular-brands__item {
        width: auto !important;
      }
    }
  }

  &__item {
    width: calc(16.66667% - 48px);
    margin: 0 24px 32px;

    @include bp('max', 1023px) {
      width: calc(33.33333% - 108px);
    }

    @include bp('max', $md3) {
      width: calc(50% - 8px);
      margin: 0 0 16px;
      padding: 0 5%;
    }

    @include bp('max', 360px) {
      padding: 0;
    }
  }

  &__link {
    @include bp('max', $md3) {
      display: block;
      max-width: 200px;
      margin: 0 auto;
    }

    img {
      object-fit: contain !important;
    }
  }
}
</style>
