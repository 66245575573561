<template>
  <div class="main-page">
    <Alert v-if="mergedCart" class="cart__alert" type="success" @close="closeAlertMergedCart()">
      <template #content>
        <p>{{ $t('cart.alerts.mergedCart') }}</p>
        <p>{{ $t('cart.alerts.checkCart') }}</p>
      </template>
    </Alert>

    <template v-if="banners && banners.length">
      <DynamicBanner v-for="banner in banners" :key="`b-${banner.id}`" :info="banner" loading="default" />
    </template>

    <!--    <Categories v-if="frequentCategories" :categories="frequentCategories" />-->

    <div v-if="rrID" :data-retailrocket-markup-block="rrID" />

    <ProductSection
      v-if="news.items && news.items.length"
      :banner="bannersNew[0]"
      :products="news.items"
      :ratio="ratio"
      :sizes="sizes"
      :title="$t('shared.novelty').toString()"
      btn-link="/catalog/filters/badge-novinka/"
    />

    <ProductSection
      v-if="bestsellers.items && bestsellers.items.length"
      :banner="bannersBestsellers[0]"
      :banner-left="true"
      :products="bestsellers.items"
      :ratio="ratio"
      :sizes="sizes"
      :title="$t('shared.bestsellers').toString()"
      btn-link="/catalog/filters/badge-bestseller/"
    />

    <PopularBrands v-if="brands && brands.length" :brands="brands" />

    <LoaderDots v-if="$fetchState.pending" />
    <ProductGroups :product-group="products" class="main-page__catalog" @get-items="getNextPageItems" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import env from '@/.env.json';
// import Categories from '@/components/catalog/categories';
import ProductGroups from '@/components/catalog/productGroups';
import Alert from '@/components/elements/Alert';
import DynamicBanner from '@/components/elements/DynamicBanner';
import LoaderDots from '@/components/elements/LoaderDots';
import bannerTypes from '@/plugins/enums/banners';
import PopularBrands from '~/components/catalog/popularBrands';
import ProductSection from '~/components/catalog/productSection/index.vue';
import pageMeta from '~/mixins/pageMeta';

export default {
  name: 'MainPage',
  components: {
    ProductSection,
    PopularBrands,
    Alert,
    DynamicBanner,
    // Categories,
    ProductGroups,
    LoaderDots,
  },
  mixins: [pageMeta],
  beforeRouteLeave(to, from, next) {
    if (to.name === 'auth') {
      this.showAuthModal(to);
    } else {
      next();
    }
  },
  layout: 'main',
  async asyncData({ $api }) {
    const response = await Promise.allSettled([
      $api.general.getBanners(bannerTypes.HEADER),
      $api.brands.getBrands('popular'),
      $api.general.getPageMeta(),
      $api.catalog.getCatalogHot({ badgeId: 1 }),
      $api.catalog.getCatalogHot({ badgeId: 2 }),
      $api.general.getBanners(bannerTypes.MAIN_NEW),
      $api.general.getBanners(bannerTypes.MAIN_BEST),
    ]);

    const [
      { value: banners },
      { value: brands },
      { value: meta },
      { value: news },
      { value: bestsellers },
      { value: bannersNew },
      { value: bannersBestsellers },
    ] = response;

    return {
      pageTitle: meta?.title || '',
      pageMetaData: meta?.data || [],
      banners,
      brands,
      news,
      bestsellers,
      bannersNew,
      bannersBestsellers,
    };
  },
  data() {
    return {
      products: {},
      banners: [],
      checkingRR: null,
      rrID: env.RETAIL_ROCKET_MAIN_ID,
      rrLoaded: false,
      ratio: {
        mobile: '100%',
        tablet: '74.77%',
        pc: '126.19%',
      },
      sizes: {
        mobile: {
          width: 768,
          height: 768,
        },
        tablet: {
          width: 642,
          height: 480,
        },
        pc: {
          width: 600,
          height: 931,
        },
      },
    };
  },

  async fetch() {
    try {
      const response = await this.$api.catalog.getFeed();
      this.$set(this.products, 'items', response);
    } catch (error) {
      console.error(error);
    }
  },

  computed: {
    // ...mapState('catalog', ['frequentCategories']),
    ...mapState('auth', ['hasSession', 'isOpenedAuthModal']),
    ...mapState('cart', ['mergedCart']),
  },

  watch: {
    rrLoaded(value) {
      if (value) {
        window.retailrocket.markup.render();
        clearInterval(this.checkingRR);
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.checkingRR = setInterval(() => {
        if (!this.isLoading && window.retailrocket) {
          this.rrLoaded = true;
        }
      }, 1000);
    });
  },

  methods: {
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    ...mapActions('cart', ['setMergedCart']),
    showAuthModal() {
      this.setIsOpenedAuthModal(true);
    },
    async getNextPageItems() {
      try {
        const response = await this.$api.catalog.getFeed();
        response.forEach((item) => this.products.items.push(item));
      } catch (error) {
        console.error(error);
      }
    },
    closeAlertMergedCart() {
      this.setMergedCart(false);
    },
  },
};
</script>

<style>
.main-page {
  margin-top: 16px;
}

@media all and (min-width: 1024px) {
  .main-page__catalog .product-grid__item {
    width: 24.999%;
  }
}
</style>
